<div class="filters" *ngIf="!prayerId">
  <app-status (onselect)="statusFilter($event)"> </app-status>
  <app-date (onselect)="dateFilter($event)"> </app-date>
</div>
<div class="card" [ngClass]="{ 'card--no-filters': prayerId }">
  <div class="card-header">
    <div class="card-header-column">User</div>
    <div class="card-header-column">Prayer</div>
    <div class="card-header-column">Linked prayer</div>
    <div class="card-header-column">Report Reason</div>
    <div class="card-header-column">Date</div>
    <div class="card-header-column">Status</div>
    <div class="card-header-column"></div>
  </div>
  <div class="card-body card-body--table">
    <div
      class="card-body-row"
      *ngFor="let comment of list; index as i"
      [id]="'comment-' + comment.id"
      [ngClass]="{
        'card-body-row--prayer-request': prayerId,
        'card-body-row--selected': commentId === comment.id
      }"
      (click)="redirect(comment.prayer?.id, comment.id)"
    >
      <div class="card-body-col card-body-col--user">
        <div class="card-body-col-text-wrap">
          <span>
            {{ comment.user?.first_name }} {{ comment.user?.last_name }}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div
          class="card-body-col-text-wrap"
          [ngClass]="{ 'card-body-col-text-wrap--full-comment': prayerId }"
        >
          <span>
            {{ comment.comment }}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div class="card-body-col-text-wrap">
          <span>
            {{ comment.prayer?.title }}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div class="card-body-col-text-wrap">
          <span class="visible-text">
            {{ comment.reports[0]?.text }}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div class="card-body-col-text-wrap">
          <span>
            {{ comment.creation_date | date : DATE_FORMAT }}
          </span>
        </div>
      </div>

      <div class="card-body-col card-body-col--status">
        <div class="card-body-col-text-wrap">
          <span>
            {{ comment.status }}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div class="toolbar mdc-menu-surface--anchor">
          <button class="mdc-icon-button" (click)="openMenu($event, i)">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6.4002" cy="16" r="3.2" fill="#5EA8FF"></circle>
              <circle cx="25.6001" cy="16" r="3.2" fill="#5EA8FF"></circle>
              <circle cx="16.0003" cy="16" r="3.2" fill="#5EA8FF"></circle>
            </svg>
          </button>

          <div class="mdc-menu mdc-menu-surface" [id]="'menu-surface-' + i">
            <ul
              class="mdc-list"
              role="menu"
              aria-hidden="true"
              aria-orientation="vertical"
              tabindex="-1"
            >
              <li
                class="mdc-list-item"
                role="menuitem"
                (click)="post($event, comment)"
                *ngIf="comment.status !== 'posted'"
              >
                <span class="mdc-list-item__text"> Post </span>
              </li>
              <li
                class="mdc-list-item mdc-list-item--accent"
                role="menuitem"
                *ngIf="comment.status !== 'blocked'"
                (click)="remove($event, comment)"
              >
                <span class="mdc-list-item__text"> Block </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
