import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatDialogModule } from "@angular/material";

import { QuillModule } from "ngx-quill";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "ng-pick-datetime";

import { AppComponent } from "./app.component";
import { AppMaterialModule } from "./angular-material/app-material.module";
import { AuthorizationComponent } from "./authorization/authorization.component";
import { PraysComponent } from "./prays/prays.component";
import { CreatePrayComponent } from "./create-pray/create-pray.component";
import { AgreementTermsComponent } from "./agreement-terms/agreement-terms.component";
import { SidenavControlsComponent } from "./sidenav-controls/sidenav-controls.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { DataService } from "./data.service";
import { AuthorizationService } from "./authorization.service";
import { AuthGuard } from "./auth.guard.service";
import { SearchPipe } from "./search.pipe";
import { StatusPipe } from "./status.pipe";
import { AuthInterceptor } from "./auth.interceptor";
import { HeaderComponent } from "./header/header.component";
import { CommonInterceptor } from "./services/common.interceptor";
import { SentryService } from "./services/sentry.service";
import { DateComponent } from "./filters/date/date.component";
import { StatusComponent } from "./filters/status/status.component";
import { CommentsComponent } from "./comments/comments.component";
import { FlaggedExpressionsComponent } from "./flagged-expressions/flagged-expressions.component";
import { FlaggedExpressionsNewComponent } from "./flagged-expressions/flagged-expressions-new/flagged-expressions-new.component";
import { PrayerPreviewComponent } from "./prayer-preview/prayer-preview.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { UpdateLinksComponent } from "./update-links/update-links.component";
import { UpdateLinkMenuResolver } from "./resolvers";
import { CanDeactivateGuard } from "./guards";
import { EditorComponent } from "./editor/editor.component";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "./const";

const appRoutes: Routes = [
  {
    path: "authorization",
    component: AuthorizationComponent,
  },
  {
    path: "prayers",
    component: PraysComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "prayers/edit/:id",
    component: CreatePrayComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: "prayers/new",
    component: CreatePrayComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: "",
        component: CreatePrayComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "links",
    component: UpdateLinksComponent,
    resolve: {
      links: UpdateLinkMenuResolver,
    },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: "comments",
    component: CommentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "flagged-expressions",
    component: FlaggedExpressionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "flagged-expressions/new",
    component: FlaggedExpressionsNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "/prayers",
  },
];

@NgModule({
  declarations: [
    AppComponent,
    AuthorizationComponent,
    PraysComponent,
    CreatePrayComponent,
    AgreementTermsComponent,
    SidenavControlsComponent,
    PaginationComponent,
    SearchPipe,
    StatusPipe,
    HeaderComponent,
    DateComponent,
    StatusComponent,
    CommentsComponent,
    FlaggedExpressionsComponent,
    FlaggedExpressionsNewComponent,
    PrayerPreviewComponent,
    ConfirmDialogComponent,
    UpdateLinksComponent,
    EditorComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    AppMaterialModule,
    QuillModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [
    DataService,
    AuthorizationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryService,
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "fr-CH" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
