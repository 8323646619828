var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DATE_FORMAT } from './../const';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '../services/filter.service';
import { CommentsService } from '../services/comments.service';
import { HeaderService } from '../services/header.service';
import { BaseListComponent } from '../base-list-component/base-list.component';
var CommentsComponent = /** @class */ (function (_super) {
    __extends(CommentsComponent, _super);
    function CommentsComponent(filterService, commentsService, headerService, route, router) {
        var _this = _super.call(this, commentsService, headerService, filterService) || this;
        _this.filterService = filterService;
        _this.commentsService = commentsService;
        _this.headerService = headerService;
        _this.route = route;
        _this.router = router;
        _this.list = _this.list;
        _this.DATE_FORMAT = DATE_FORMAT;
        return _this;
    }
    CommentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.prayerId = this.route.snapshot.params.id;
        if (this.prayerId) {
            this.subs = new Subscription();
            this.commentsService.getByPrayerId(this.prayerId).subscribe();
            this.subs.add(this.commentsService.list.subscribe(function (list) {
                _this.list = list;
            }));
            this.subs.add(this.route.queryParams.subscribe(function (query) {
                _this.commentId = parseInt(query['comment-id'], 10);
            }));
        }
        else {
            _super.prototype.ngOnInit.call(this);
        }
    };
    CommentsComponent.prototype.statusFilter = function (event) {
        this.filterService.changeStatusFilterOption(event);
        this.commentsService.search(this.headerService.searchValue).subscribe();
    };
    CommentsComponent.prototype.post = function (event, comment) {
        event.stopPropagation();
        event.preventDefault();
        this.commentsService.postComment(comment.id).subscribe();
    };
    CommentsComponent.prototype.redirect = function (prayerId, commentId) {
        if (this.commentId) {
            return;
        }
        this.router.navigate(['/prayers', 'edit', prayerId], {
            queryParams: {
                'comment-id': commentId
            }
        });
    };
    return CommentsComponent;
}(BaseListComponent));
export { CommentsComponent };
