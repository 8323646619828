<div class="filters">
  <app-date (onselect)="dateFilter($event)"></app-date>
</div>
<div class="card">
  <div class="card-header">
    <div class="card-header-column">Title</div>
    <div class="card-header-column">Urgent</div>
    <div class="card-header-column">Description</div>
    <div class="card-header-column">Country</div>
    <div class="card-header-column">Scheduled date & time</div>
    <div class="card-header-column">Posted date & time</div>
    <div class="card-header-column">Prayers counter</div>
    <div class="card-header-column">Comments counter</div>
    <div class="card-header-column"></div>
  </div>
  <div class="card-body card-body--table">
    <ng-container *ngFor="let prayer of list; index as i">
      <div (click)="togglePreviewMode(prayer)" class="card-body-row">
        <div class="card-body-col card-body-col--title">
          <div class="card-body-col-text-wrap">
            <span>
              {{ prayer.title }}
            </span>
          </div>
        </div>
        <div class="card-body-col card-body-col--is_urgent">
          <mat-icon *ngIf="prayer.is_urgent">notification_important</mat-icon>
        </div>
        <div class="card-body-col card-body-col--description">
          <span>
            {{ prayer.preview_story }}
          </span>
        </div>
        <div class="card-body-col card-body-col--country">
          <span>
            {{ prayer.country || "N/A" }}
          </span>
        </div>
        <div class="card-body-col card-body-col--date">
          <span>
            {{ prayer.schedule_date | date : DATE_FORMAT }}
            {{ prayer.schedule_date | date : "shortTime" }}
          </span>
        </div>
        <div class="card-body-col card-body-col--date">
          <span>
            {{ postedDate(prayer) }}
          </span>
        </div>
        <div class="card-body-col">
          <div class="icons">
            <span><i class="icon icon-ios"></i></span>
            <span>{{ prayer.counter_ios }} </span>
          </div>
          <div class="icons">
            <span><i class="icon icon-android"></i></span>
            <span>{{ prayer.counter_android }}</span>
          </div>
        </div>
        <div class="card-body-col">
          <div class="icons">
            <span><i class="icon icon-ios"></i></span>
            <span>{{ prayer.ios_comment_counter }}</span>
          </div>
          <div class="icons">
            <span><i class="icon icon-android"></i></span>
            <span>{{ prayer.android_comment_counter }}</span>
          </div>
        </div>
        <div class="card-body-col">
          <div class="toolbar mdc-menu-surface--anchor">
            <button class="mdc-icon-button" (click)="openMenu($event, i)">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.4002" cy="16" r="3.2" fill="#5EA8FF"></circle>
                <circle cx="25.6001" cy="16" r="3.2" fill="#5EA8FF"></circle>
                <circle cx="16.0003" cy="16" r="3.2" fill="#5EA8FF"></circle>
              </svg>
            </button>

            <div class="mdc-menu mdc-menu-surface" [id]="'menu-surface-' + i">
              <ul
                class="mdc-list"
                role="menu"
                aria-hidden="true"
                aria-orientation="vertical"
                tabindex="-1"
              >
                <li
                  class="mdc-list-item"
                  role="menuitem"
                  (click)="toggleFeatured($event, prayer)"
                >
                  <span class="mdc-list-item__text">
                    {{
                      prayer.priority ? "Make non-featured" : "Make featured"
                    }}
                  </span>
                </li>
                <li
                  class="mdc-list-item"
                  role="menuitem"
                  (click)="togglePrayerCommentStatus($event, prayer)"
                >
                  <span class="mdc-list-item__text">
                    {{
                      prayer.is_comment_enable ? "Disable" : "Enable"
                    }}
                    submitting prayers
                  </span>
                </li>
                <li
                  class="mdc-list-item mdc-list-item--accent"
                  role="menuitem"
                  (click)="remove($event, prayer)"
                >
                  <span class="mdc-list-item__text"> Delete </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <app-prayer-preview
        *ngIf="isDisplayPreview(prayer)"
        [prayer]="prayer"
        (toggleCommentStatus)="togglePrayerCommentStatus($event, prayer)"
        (toggleFeatureStatus)="toggleFeatured($event, prayer)"
        (edit)="onEditPrayer($event, prayer)"
        (delete)="remove($event, prayer)"
      ></app-prayer-preview>
    </ng-container>
  </div>
</div>
