var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DATE_FORMAT, DATE_TIME_FORMAT } from './../const';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { PrayersService } from '../services/prayers.service';
import { HeaderService } from '../services/header.service';
import { FilterService } from '../services/filter.service';
import { BaseListComponent } from '../base-list-component/base-list.component';
import { ConfirmationDialogService } from '../services';
var PraysComponent = /** @class */ (function (_super) {
    __extends(PraysComponent, _super);
    function PraysComponent(prayersService, headerService, router, confirmationDialogService, filterService) {
        var _this = _super.call(this, prayersService, headerService, filterService) || this;
        _this.prayersService = prayersService;
        _this.headerService = headerService;
        _this.router = router;
        _this.confirmationDialogService = confirmationDialogService;
        _this.filterService = filterService;
        // a hack, so list has a specific model behind it
        _this.list = _this.list;
        _this.DATE_FORMAT = DATE_FORMAT;
        _this.displayPrayerPreview = new Map();
        return _this;
    }
    /**
     * Change 'featured' property of the prayer
     * @param event
     * @param prayer
     */
    PraysComponent.prototype.toggleFeatured = function (event, prayer) {
        var _this = this;
        event.stopPropagation();
        event.preventDefault();
        this.prayersService.toggleFeatured(prayer.id).subscribe(function (response) {
            _this.prayersService.search(_this.headerService.searchValue)
                .subscribe(function (prayers) {
                _this.displayPrayerPreview.set(prayer.id, false);
                _this.list = prayers;
            });
        });
    };
    /**
     * Toggle 'is_comment_enable' of prayer true or false
     * @param event - Event
     * @param prayer - prayer [Prayer]
     */
    PraysComponent.prototype.togglePrayerCommentStatus = function (event, prayer) {
        var _this = this;
        event.stopPropagation();
        event.preventDefault();
        this.prayersService.togglePrayerCommentStatus(prayer.id, !prayer.is_comment_enable)
            .subscribe(function () {
            _this.prayersService.search(_this.headerService.searchValue)
                .subscribe(function (prayers) {
                _this.displayPrayerPreview.set(prayer.id, false);
                _this.list = prayers;
            });
        });
    };
    /**
     * Checks if prayer is posted and shows date if true
     * @param prayer
     */
    PraysComponent.prototype.postedDate = function (prayer) {
        var scheduleDateUnix = Date.parse(prayer.schedule_date);
        var creationDateUnix = Date.parse(prayer.creation_date);
        if (prayer.schedule_date) {
            if (scheduleDateUnix < creationDateUnix) {
                return '-';
            }
            else {
                return moment(scheduleDateUnix).format(DATE_TIME_FORMAT);
            }
        }
        return moment(creationDateUnix).format(DATE_TIME_FORMAT);
    };
    /**
     * Toggle preview mode
     * @param prayer
     */
    PraysComponent.prototype.togglePreviewMode = function (prayer) {
        this.displayPrayerPreview.set(prayer.id, !this.isDisplayPreview(prayer));
    };
    /**
     * Check is display preview mode for prayer
     * @param prayer
     */
    PraysComponent.prototype.isDisplayPreview = function (prayer) {
        return this.displayPrayerPreview.get(prayer.id);
    };
    PraysComponent.prototype.onEditPrayer = function (event, prayer) {
        event.stopPropagation();
        event.preventDefault();
        this.router.navigate(['/prayers', 'edit', prayer.id]);
    };
    PraysComponent.prototype.remove = function (event, item) {
        var _this = this;
        event.stopPropagation();
        event.preventDefault();
        this.confirmationDialogService.canDeletePrayerDialog()
            .afterClosed()
            .pipe(take(1))
            .subscribe(function (result) {
            if (result && result.isAccept) {
                _super.prototype.remove.call(_this, event, item);
            }
        });
    };
    return PraysComponent;
}(BaseListComponent));
export { PraysComponent };
